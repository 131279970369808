import React from "react";
import Icon from "./icon";
import styles from "./showreel.module.css";

const Showreel = ({ src }) => {
  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <iframe
            src={src}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            className={styles.iframe}
            allow="autoplay; fullscreen"
          />
          <div className={styles.chevron}>
            <Icon symbol="chevronDown" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showreel;
