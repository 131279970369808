import React from 'react'
import {cn} from '../lib/helpers'
import styles from './section.module.css'

const Section = ({children, bg}) => {
  const section = cn(
    styles.section,
    bg === 'lightGeo' && styles.lightGeo,
    bg === 'darkGeo' && styles.darkGeo,
    bg === 'light' && styles.light,
    bg === 'pink' && styles.pink,
    bg === 'dark' && styles.dark
  )

  return (
    <section className={section}>
      <div className={styles.inner}>
        {children}
      </div>
    </section>
  )
}

export default Section
