import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import styles from "./section-intro.module.css";
import sectionStyles from "./section.module.css";

import logo from "../images/squire-logo.svg";

import Section from "./section";
import Button from "./button";

const SectionIntro = () => {
  // const section = cn()

  return (
    <Section bg="lightGeo">
      <div className={styles.inner}>
        <img src={logo} alt="Squire logo" className={styles.logo} />
        <h1 className={styles.title}>Squire is a creative content studio</h1>
        <p className={styles.para}>
          We strive to push the boundaries of content production, creating work that authentically
          spans multiple platforms with the common thread of captivating visual storytelling at its
          heart.
        </p>
        <Link to="/about-us">
          <Button>Meet the team</Button>
        </Link>
      </div>
    </Section>
  );
};

export default SectionIntro;
