import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import { cn } from "../lib/helpers";
import styles from "./section-directors.module.css";
import sectionStyles from "./section.module.css";
import Shield1 from "../images/shields/1.svg";

import Section from "./section";
import Button from "./button";

export const directorsQuery = graphql`
  query directorsQuery {
    directors: allSanityDirector(limit: 6) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

const SectionDirectors = () => {
  return (
    <StaticQuery
      query={directorsQuery}
      render={({ directors }) => {
        const directorNodes = directors
          ? mapEdgesToNodes(directors).filter(filterOutDocsWithoutSlugs)
          : [];
        return (
          <Section bg="white">
            <div className={styles.centred}>
              <h2 className={sectionStyles.title}>Meet our directors</h2>
              <div className={styles.shields}>
                {directorNodes.map((director) => (
                  <img
                    key={director.slug.current}
                    src={director.image.asset.url}
                    alt={`${director.title}’s shield`}
                    className={styles.shield}
                  />
                ))}
              </div>
              <p className={cn(sectionStyles.para, styles.para)}>
                We believe great work starts with trust and team spirit.
              </p>
              <Link to="/meet-our-directors">
                <Button>Meet our directors</Button>
              </Link>
            </div>
          </Section>
        );
      }}
    />
  );
};

export default SectionDirectors;
