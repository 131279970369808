import React from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import SEO from "../components/seo";

// Sections
import SectionIntro from "../components/section-intro";
import SectionWork from "../components/section-work";
import SectionDirectors from "../components/section-directors";

// Other components
import Showreel from "../components/showreel";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    sanityPageHome {
      showreelVimeoId
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const sanityPageHome = (data || {}).sanityPageHome;

  return (
    <Layout darkLight="dark">
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Showreel
        src={`https://player.vimeo.com/video/${sanityPageHome.showreelVimeoId}?background=1`}
      />
      <SectionIntro />
      <SectionWork />
      <SectionDirectors />
    </Layout>
  );
};

export default IndexPage;
