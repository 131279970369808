import React from "react";
import { cn } from "../lib/helpers";
import { Link, graphql, StaticQuery } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import styles from "./section-work.module.css";
import sectionStyles from "./section.module.css";

import Section from "./section";
import Button from "./button";

export const workCategoriesQuery = graphql`
  query workCategoriesSectionQuery {
    categories: allSanityWorkCategory(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

const SectionWork = () => {
  return (
    <StaticQuery
      query={workCategoriesQuery}
      render={({ categories }) => {
        const categoryNodes = categories
          ? mapEdgesToNodes(categories).filter(filterOutDocsWithoutSlugs)
          : [];
        return (
          <Section bg="darkGeo">
            <div className={styles.centred}>
              <h2 className={cn(sectionStyles.title, styles.title)}>Our work</h2>
              <div className={styles.categoryGrid}>
                {categoryNodes.map((category) => (
                  <div key={category.slug.current} className={styles.category}>
                    <img
                      className={styles.categoryImage}
                      src={category.image.asset.url}
                      alt={category.title}
                    />
                    <div className={styles.categoryTitle}>{category.title}</div>
                  </div>
                ))}
              </div>
              <Link to="/our-work">
                <Button>See our work</Button>
              </Link>
            </div>
          </Section>
        );
      }}
    />
  );
};

export default SectionWork;
